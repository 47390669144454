// src/components/WorldQuests.js
import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Paper,
    Link,
    CircularProgress,
} from "@mui/material";
import { useUser } from "../context/UserContext";

const MAX_WORLD_QUESTS = 30;

const WorldQuests = () => {
    const { user } = useUser();
    const [region, setRegion] = useState("us");
    const [worldQuestId, setWorldQuestId] = useState("");
    const [monitoredWorldQuests, setMonitoredWorldQuests] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [warningMessage, setWarningMessage] = useState("");
    const [loading, setLoading] = useState(true);

    const API_URL = process.env.REACT_APP_JEEVES_API_URL;

    // Fetch monitored world quests on component load
    useEffect(() => {
        if (user) {
            fetch(`${API_URL}/user/${user.id}/notifications/world-quests`, {
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Failed to fetch world quests");
                    }
                    return response.json();
                })
                .then((data) => setMonitoredWorldQuests(data.data))
                .finally(() => setLoading(false))
                .catch((error) => console.error("Error fetching world quests:", error));
        }
    }, [user, API_URL]);

    // Add a new world quest
    const handleAddWorldQuest = () => {
        if (!worldQuestId) {
            setErrorMessage("World Quest ID cannot be empty.");
            return;
        }

        if (monitoredWorldQuests.some((quest) => quest.worldQuestId === parseInt(worldQuestId))) {
            setErrorMessage("Duplicate World Quest ID. This quest is already being monitored.");
            return;
        }

        if (monitoredWorldQuests.length >= MAX_WORLD_QUESTS) {
            setWarningMessage(`You can only monitor up to ${MAX_WORLD_QUESTS} world quests.`);
            return;
        }

        const newQuest = { region, worldQuestId: parseInt(worldQuestId) };

        fetch(`${API_URL}/user/${user.id}/notifications/world-quests`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user.bearerToken}`,
            },
            body: JSON.stringify(newQuest),
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((err) => {
                        throw new Error(err.message || "Failed to add world quest");
                    });
                }
                return response.json();
            })
            .then((data) => {
                setMonitoredWorldQuests((prev) => [...prev, data.data]);
                setWorldQuestId(""); // Clear input field
                setErrorMessage("");
                setWarningMessage("");
            })
            .catch((error) => {
                if (error.message === "Invalid Quest") {
                    setErrorMessage("Invalid Quest: Please check the World Quest ID.");
                } else {
                    console.error("Error adding world quest:", error);
                }
            });
    };

    // Remove a world quest
    const handleRemoveWorldQuest = (id) => {
        fetch(`${API_URL}/user/${user.id}/notifications/world-quests/${id}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${user.bearerToken}`,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to remove world quest");
                }
                setMonitoredWorldQuests((prev) => prev.filter((quest) => quest.id !== id));
                setWarningMessage("");
            })
            .catch((error) => console.error("Error removing world quest:", error));
    };

    if (loading) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ mt: 4 }}>
            <Typography variant="h4" gutterBottom>
                World Quests
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2 }}>
                To find the World Quest Id, lookup the World Quest on Wowhead and grab the Id from the URL of the World Quest Page.
            </Typography>
            {errorMessage && (
                <Typography color="error" gutterBottom>
                    {errorMessage}
                </Typography>
            )}
            {warningMessage && (
                <Typography color="warning" gutterBottom>
                    {warningMessage}
                </Typography>
            )}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: 2,
                    marginBottom: 3,
                }}
            >
                <FormControl sx={{ minWidth: 120, "& .MuiInputLabel-root": { paddingRight: 0.5 } }}>
                    <InputLabel
                        id="region"
                        sx={{
                            backgroundColor: "background.default", // Match the background color
                            paddingLeft: 1,
                            paddingRight: 1,
                            "&.Mui-focused": {
                                backgroundColor: "background.default", // Match the background color when focused
                            },
                        }}>
                        Region
                    </InputLabel>
                    <Select
                        labelId="region"
                        value={region}
                        onChange={(e) => setRegion(e.target.value)}
                    >
                        <MenuItem value="us">US</MenuItem>
                        <MenuItem value="eu">EU</MenuItem>
                        <MenuItem value="tw">TW</MenuItem>
                        <MenuItem value="kr">KR</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    label="World Quest ID"
                    type="number"
                    value={worldQuestId}
                    onChange={(e) => setWorldQuestId(e.target.value)}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddWorldQuest}
                    disabled={monitoredWorldQuests.length >= MAX_WORLD_QUESTS}
                >
                    Submit
                </Button>
            </Box>
            <TableContainer component={Paper} sx={{ maxWidth: "800px", margin: "0 auto" }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Region</TableCell>
                            <TableCell>World Quest ID</TableCell>
                            <TableCell>World Quest Title</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {monitoredWorldQuests.map((quest) => (
                            <TableRow key={quest.id}>
                                <TableCell>{quest.region}</TableCell>
                                <TableCell>{quest.worldQuestId}</TableCell>
                                <TableCell>
                                    <Link
                                        href={`https://www.wowhead.com/quest=${quest.worldQuestId}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {quest.worldQuestName.length > 100
                                            ? `${quest.worldQuestName.slice(0, 97)}...`
                                            : quest.worldQuestName}
                                    </Link>
                                </TableCell>
                                <TableCell align="right">
                                    <Button
                                        variant="text"
                                        color="error"
                                        onClick={() => handleRemoveWorldQuest(quest.id)}
                                    >
                                        X
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default WorldQuests;

// Include wowhead tooltips embed
if (typeof window !== "undefined") {
    const script1 = document.createElement("script");
    script1.innerHTML = "const whTooltips = {colorLinks: true, iconizeLinks: true, renameLinks: true};";
    document.head.appendChild(script1);

    const script2 = document.createElement("script");
    script2.src = "https://wow.zamimg.com/js/tooltips.js";
    script2.async = true;
    document.head.appendChild(script2);
}
