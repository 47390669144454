import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Switch,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormGroup,
  FormControlLabel,
  Grid,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Tooltip,
  IconButton
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Link } from "react-router-dom";
import { useServer } from "../context/ServerContext";
import { useServerSettings } from "../context/ServerSettingsContext";

const RoleManagement = () => {
  const { server, serverRoles } = useServer();
  const {
    roleManagementSettings,
    setRoleManagementSettings,
    integrations,
    saveRoleManagementSettings,
    integrationsLoading,
    roleManagementLoading
  } = useServerSettings();

  const [customFormatVisible, setCustomFormatVisible] = useState(false);

  useEffect(() => { }, [roleManagementSettings, serverRoles, integrations]);

  // Ensure custom format box is visible when 'custom' is selected on reload
  useEffect(() => {
    if (roleManagementSettings?.nickname?.nameFormat === "custom") {
      setCustomFormatVisible(true);
    } else {
      setCustomFormatVisible(false);
    }
  }, [roleManagementSettings?.nickname?.nameFormat]);

  const handleSave = () => {
    saveRoleManagementSettings();
  };

  if (roleManagementLoading || integrationsLoading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h4" gutterBottom>
        Role Management
      </Typography>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={roleManagementSettings?.enabled || false}
              onChange={(event) =>
                setRoleManagementSettings({
                  ...roleManagementSettings,
                  enabled: event.target.checked,
                })
              }
            />
          }
          label="Enable Role Management?"
        />
      </FormGroup>
      <br />

      {/* New Members (On-Join) */}
      <Box sx={{ mt: 3 }}>
        <Typography variant="h5" gutterBottom>
          New Members (On-Join)
          <Tooltip title="When set, Jeeves will grant this role to everyone who joins your server" placement="right">
            <IconButton>
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Typography>
        <FormGroup>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="role-select-label">Role</InputLabel>
                <Select
                  labelId="role-select-label"
                  value={roleManagementSettings?.onJoin?.role || ''}
                  onChange={(event) =>
                    setRoleManagementSettings({
                      ...roleManagementSettings,
                      onJoin: { ...roleManagementSettings.onJoin, role: event.target.value || null },
                    })
                  }
                  label="Role"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {serverRoles.map((role) => (
                    <MenuItem key={role.id} value={role.id}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={roleManagementSettings?.onJoin?.guestMode || false}
                    onChange={(event) =>
                      setRoleManagementSettings({
                        ...roleManagementSettings,
                        onJoin: { ...roleManagementSettings.onJoin, guestMode: event.target.checked },
                      })
                    }
                  />
                }
                label={
                  <Box display="flex" alignItems="center">
                    Guest Mode
                    <Tooltip title="When enabled Jeeves will remove this role once the user has authorized and obtained a role from the integrations, useful for promoting people from Guest -> Verified" placement="right">
                      <IconButton>
                        <InfoIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                }
              />
            </Grid>
          </Grid>
        </FormGroup>
      </Box>


      {/* Nickname Management */}
      <Box sx={{ mt: 3 }}>
        <Typography variant="h5" gutterBottom>
          Nickname Management
          <Tooltip
            title="When enabled, Jeeves will update a user's nickname to match their main character when evaluating them for promotion/demotion."
            placement="right"
          >
            <IconButton>
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={roleManagementSettings?.nickname?.enabled || false}
                onChange={(event) =>
                  setRoleManagementSettings({
                    ...roleManagementSettings,
                    nickname: {
                      ...roleManagementSettings.nickname,
                      enabled: event.target.checked,
                    },
                  })
                }
              />
            }
            label="Enable Nickname Management?"
          />
        </FormGroup>
        {roleManagementSettings?.nickname?.enabled && (
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={6}>
              <Box display="flex" alignItems="center">
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="nickname-mode">
                    Nickname Mode
                  </InputLabel>
                  <Select
                    label="Nickname Mode"
                    value={roleManagementSettings?.nickname?.mode || ""}
                    onChange={(event) =>
                      setRoleManagementSettings({
                        ...roleManagementSettings,
                        nickname: {
                          ...roleManagementSettings.nickname,
                          mode: event.target.value,
                        },
                      })
                    }
                  >
                    <MenuItem value="suggested">Suggested</MenuItem>
                    <MenuItem value="enforced">Enforced</MenuItem>
                  </Select>
                </FormControl>

                <Tooltip
                  title={
                    <>
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Suggested:</Typography>
                      <Typography variant="body2">
                        Jeeves will only apply a nickname if a player does not already have one set.
                      </Typography>
                      <br />
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Enforced:</Typography>
                      <Typography variant="body2">
                        Jeeves will always apply a nickname, overriding any already set.
                      </Typography>
                    </>
                  }
                  placement="top"
                >
                  <IconButton>
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="name-format">Name Format</InputLabel>
                <Select
                  label="Name Format"
                  value={roleManagementSettings?.nickname?.nameFormat || ""}
                  onChange={(event) => {
                    const format = event.target.value;
                    setRoleManagementSettings({
                      ...roleManagementSettings,
                      nickname: {
                        ...roleManagementSettings.nickname,
                        nameFormat: format,
                      },
                    });
                    setCustomFormatVisible(format === "custom");
                  }}
                >
                  <MenuItem value="name">Name</MenuItem>
                  <MenuItem value="name-realm">Name-Realm</MenuItem>
                  <MenuItem value="name-realm-region">Name-Realm-Region</MenuItem>
                  <MenuItem value="custom">Custom</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Custom Format */}
            {customFormatVisible && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Custom Format"
                  variant="outlined"
                  value={roleManagementSettings?.nickname?.customFormat || ""}
                  onChange={(event) =>
                    setRoleManagementSettings({
                      ...roleManagementSettings,
                      nickname: {
                        ...roleManagementSettings.nickname,
                        customFormat: event.target.value,
                      },
                    })
                  }
                  inputProps={{ maxLength: 25, minLength: 5 }}
                />
                <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                  Custom Format Variables:
                  <br />
                  <strong>%name%</strong> - Character Name
                  <br />
                  <strong>%realm%</strong> - Character Realm
                  <br />
                  <strong>%region%</strong> - Character Region
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                  Example:
                  <br />
                  <strong>%name% (%realm%)</strong> - zugzug (area-52)
                  <br />
                  <strong>%name% - %realm% - %region%</strong> - zugzug - area-52 - us
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
      </Box>

      {/* Custom Roles */}
      <Box sx={{ mt: 3 }}>
        <Typography variant="h5" gutterBottom>
          Custom Roles
        </Typography>
        {Object.entries(roleManagementSettings?.customRoles || {}).map(
          ([name, role], index) => (
            <Box key={index} sx={{ pb: 1 }}>
              <Grid container spacing={2} key={index}>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Name"
                    variant="outlined"
                    value={name}
                    onChange={(event) => {
                      const newCustomRoles = {
                        ...roleManagementSettings?.customRoles,
                      };
                      delete newCustomRoles[name];
                      newCustomRoles[event.target.value] = role;
                      setRoleManagementSettings({
                        ...roleManagementSettings,
                        customRoles: newCustomRoles,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor={`custom-role-${index}`}>
                      Role
                    </InputLabel>
                    <Select
                      label="Role"
                      value={role}
                      onChange={(event) => {
                        const newCustomRoles = {
                          ...roleManagementSettings?.customRoles,
                        };
                        newCustomRoles[name] = event.target.value;
                        setRoleManagementSettings({
                          ...roleManagementSettings,
                          customRoles: newCustomRoles,
                        });
                      }}
                    >
                      <MenuItem value={""}>None</MenuItem>
                      {serverRoles.map((role) => (
                        <MenuItem key={role.id} value={role.id}>
                          {role.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} container alignItems="center">
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      const newCustomRoles = {
                        ...roleManagementSettings?.customRoles,
                      };
                      delete newCustomRoles[name];
                      setRoleManagementSettings({
                        ...roleManagementSettings,
                        customRoles: newCustomRoles,
                      });
                    }}
                  >
                    Remove
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )
        )}
        <Box sx={{ mt: 2 }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setRoleManagementSettings({
                ...roleManagementSettings,
                customRoles: {
                  ...roleManagementSettings?.customRoles,
                  [`Custom Role ${Object.keys(roleManagementSettings?.customRoles).length + 1
                    }`]: "",
                },
              });
            }}
          >
            Add Custom Role
          </Button>
        </Box>
      </Box>

      {/* Integrations */}
      <Box sx={{ mt: 3 }}>
        <Typography variant="h4" gutterBottom>
          Role Integrations
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Integrations apply roles based on in-game metrics. Choose an
          Integration below to edit it.
        </Typography>
        <List>
          {Object.values(integrations).map((integration) => {
            return (
              <ListItem
                key={integration.slug}
                button
                component={Link}
                to={`/servers/${server.id}/dashboard/role-management/${integration.slug}`}
              >
                <ListItemText
                  primary={integration.name}
                  secondary={
                    roleManagementSettings?.integrations?.[integration.slug]
                      ?.enabled
                      ? "Enabled"
                      : "Disabled"
                  }
                />
              </ListItem>
            );
          })}
        </List>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Button
          fullWidth
          variant="contained"
          color="success"
          onClick={handleSave}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default RoleManagement;
