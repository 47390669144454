// src/pages/Notifications.js
import React, { useEffect, useState } from "react";
import { useUser } from "../context/UserContext";
import {
    Box,
    Button,
    Grid,
    Typography,
    Paper,
} from "@mui/material";
import { styled } from "@mui/system";
import {
    Route,
    Routes,
    useNavigate,
    Link,
} from "react-router-dom";
import WorldQuests from "../components/WorldQuests";

const API_URL = process.env.REACT_APP_JEEVES_API_URL;

const DisabledButton = styled(Button)(({ theme }) => ({
    position: "relative",
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.text.disabled,
    cursor: "not-allowed",
    height: "100px",
    width: "200px",
    fontSize: "1.5rem",
    ".Mui-disabled": {
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.text.disabled,
    },
    "&::after": {
        content: '"COMING SOON"',
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%) rotate(-30deg)",
        fontSize: "1.8rem",
        fontWeight: "bold",
        color: theme.palette.error.main,
        textShadow: "1px 1px 3px rgba(0, 0, 0, 0.7)",
        opacity: 0.65,
    },
}));

const WorldEvents = () => <Typography variant="h5">World Events Content (Coming Soon)</Typography>;
const Rotations = () => <Typography variant="h5">Rotations Content (Coming Soon)</Typography>;
const Holidays = () => <Typography variant="h5">Holidays Content (Coming Soon)</Typography>;

const Notifications = () => {
    const navigate = useNavigate();
    const { user, isLoading } = useUser();

    const [notificationsActive, setNotificationsActive] = useState(null);
    const [loadingStatus, setLoadingStatus] = useState(true);
    const [statusMessage, setStatusMessage] = useState("");

    // Fetch Notification Status
    const fetchNotificationStatus = () => {
        fetch(`${API_URL}/user/${user.id}/notifications/status`, {
            headers: {
                Authorization: `Bearer ${user.bearerToken}`,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.status === "ok") {
                    setNotificationsActive(data.data.notifications_active);
                } else {
                    setStatusMessage("Error: User not found.");
                }
            })
            .catch(() => setStatusMessage("Error fetching notification status."))
            .finally(() => setLoadingStatus(false));
    };

    useEffect(() => {
        if (!isLoading && !user) {
            navigate("/");
            return;
        }
        if (user) {
            fetchNotificationStatus();
        }
    }, [user, isLoading, navigate]);

    const handleToggleNotifications = (action) => {
        const url = `${API_URL}/user/${user.id}/notifications/${action}`;
        fetch(url, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${user.bearerToken}`,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.status === "ok") {
                    setNotificationsActive(action === "activate");
                    setStatusMessage("");
                } else if (data.code === "NO_BNET_ACCOUNT") {
                    setStatusMessage(
                        "Error: No Battle.net account linked. Use the /authorize command to link your account."
                    );
                } else {
                    setStatusMessage("Error: User not found.");
                }
            })
            .catch(() => setStatusMessage("Failed to update notification status."));
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <Box sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
            {/* Main Content Container */}
            <Box sx={{ flexGrow: 1, maxWidth: "900px", textAlign: "center" }}>
                <Typography variant="h4" gutterBottom>
                    Notifications
                </Typography>
                <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ mb: 4 }}>
                    <Grid item>
                        <Button
                            component={Link}
                            to={`/user/${user.id}/notifications/world-quests`}
                            variant="contained"
                            color="primary"
                            size="large"
                            sx={{ height: "100px", width: "200px", fontSize: "1.5rem" }}
                        >
                            World Quests
                        </Button>
                    </Grid>
                    <Grid item>
                        <DisabledButton variant="contained" size="large" disabled>
                            World Events
                        </DisabledButton>
                    </Grid>
                    <Grid item>
                        <DisabledButton variant="contained" size="large" disabled>
                            Rotations
                        </DisabledButton>
                    </Grid>
                    <Grid item>
                        <DisabledButton variant="contained" size="large" disabled>
                            Holidays
                        </DisabledButton>
                    </Grid>
                </Grid>
                <Box>
                    <Routes>
                        <Route path={`world-quests`} element={<WorldQuests />} />
                        <Route path={`world-events`} element={<WorldEvents />} />
                        <Route path={`rotations`} element={<Rotations />} />
                        <Route path={`holidays`} element={<Holidays />} />
                    </Routes>
                </Box>
            </Box>

            {/* Notification Status Container */}
            <Box sx={{ ml: 4, minWidth: "300px" }}>
                <Paper elevation={3} sx={{ p: 3, textAlign: "center" }}>
                    <Typography variant="h6" gutterBottom>
                        Notification Status
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                        Status:  {notificationsActive ? "Enabled" : "Disabled"}
                    </Typography>
                    {statusMessage && (
                        <Typography color="error" gutterBottom>
                            {statusMessage}
                        </Typography>
                    )}
                    <Button
                        variant="contained"
                        color={notificationsActive ? "secondary" : "primary"}
                        onClick={() =>
                            handleToggleNotifications(
                                notificationsActive ? "deactivate" : "activate"
                            )
                        }
                        sx={{ mt: 2 }}
                    >
                        {notificationsActive ? "Deactivate" : "Activate"}
                    </Button>
                </Paper>
            </Box>
        </Box>
    );
};

export default Notifications;
