// src/components/UserInfo.js
import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Avatar,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import LogoutIcon from "@mui/icons-material/Logout";
import { useUser } from "../context/UserContext";

const LoginButton = () => {
  const { user, logout, openLoginWindow } = useUser();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSettings = () => {
    console.log("Settings clicked");
    handleMenuClose();
  };

  const handleLogout = () => {
    logout();
    handleMenuClose();
  };

  const buildAvatar = (user) => {
    return user.avatar
      ? `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png`
      : `https://cdn.discordapp.com/embed/avatars/${user.discriminator % 5
      }.png`;
  };

  if (user) {
    return (
      <>
        <IconButton onClick={handleMenuOpen}>
          <Avatar alt={user.username} src={buildAvatar(user)} />
          <div
            style={{ paddingLeft: "10px", color: "white", fontSize: "18px" }}
          >
            {user.name}
          </div>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem component={RouterLink}
            to={`/user/${user.id}/notifications`}
            onClick={handleMenuClose}
            sx={{ color: "inherit" }}>
            <ListItemIcon>
              <EditNotificationsIcon />
            </ListItemIcon>
            <ListItemText primary="Notifications" />
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </MenuItem>
        </Menu>
      </>
    );
  }

  return (
    <Button
      variant="outlined"
      color="inherit"
      startIcon={<FontAwesomeIcon icon={faDiscord} />}
      onClick={openLoginWindow}
      sx={{ ml: 2 }}
    >
      Login with Discord
    </Button>
  );
};

export default LoginButton;
